import React, { useEffect, useRef } from "react";

const UtterancesComments = ({ repo, theme, issueTerm }) => {
  const ref = useRef();

  useEffect(() => {
    const script = document.createElement("script");

    const config = {
      src: "https://utteranc.es/client.js",
      repo: repo,
      "issue-term": issueTerm,
      theme: theme,
      crossOrigin: "anonymous",
      defer: true,
    };

    Object.entries(config).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    const timer = setTimeout(() => {
      ref.current.append(script);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [repo, theme, issueTerm]);

  return <div ref={ref} />;
};

UtterancesComments.defaultProps = {
  theme: "github-light",
  issueTerm: "pathname",
};

export default UtterancesComments;

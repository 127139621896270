import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-mdx";
import ReadLink from "../components/read-link";
import UtterancesComments from "../components/comments";

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        date
        description
      }
      code {
        body
      }
    }
  }
`;

export default function PostTemplate({ data: { mdx: post } }) {
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.frontmatter.description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
        <meta
          property="og:image"
          content="https://richiemccoll.com/images/rich.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@richiemccoll" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta
          name="twitter:description"
          content={post.frontmatter.description}
        />
        <meta
          name="twitter:image"
          content="https://richiemccoll.com/images/rich.png"
        />
      </Helmet>
      <h1>{post.frontmatter.title}</h1>
      <p
        css={css`
          font-size: 0.75rem;
        `}
      >
        Published by {post.frontmatter.author} on{" "}
        {new Date(post.frontmatter.date).toLocaleDateString()}
      </p>
      <MDXRenderer>{post.code.body}</MDXRenderer>
      <UtterancesComments
        repo="richiemccoll/blog-comments"
        issueTerm={post.frontmatter.title}
        theme="github-light"
      />
      <ReadLink to="/">&larr; Back to all posts</ReadLink>
    </Layout>
  );
}
